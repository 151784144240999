import { de, enUS, fr, ru, tr } from 'date-fns/locale';

import { SupportedLanguages } from '~~/core';

export function useDateFnsLocale() {
  const { locale } = useI18n();

  return computed(() => {
    switch (locale.value) {
      case SupportedLanguages.FRENCH:
        return fr;

      case SupportedLanguages.GERMAN:
        return de;

      case SupportedLanguages.RUSSIAN:
        return ru;

      case SupportedLanguages.TURKISH:
        return tr;

      case SupportedLanguages.ENGLISH:
      default:
        return enUS;
    }
  });
}
